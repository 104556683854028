import React, { Component } from "react";
import axios from "axios";
import cheerio from "cheerio";
import getQuestions from "../components/getQuestions";
import { CheckCircleFill, XCircleFill } from "react-bootstrap-icons";
// import { Button, Collapse } from "bootstrap";

let timer = null;
// let firstTimeTimer = 1;

class Question extends Component {
  state = {
    page: 0,
    questionNumber: 0,
    questionsList: [],
    prevQuestion: "",
    currentQuestion: "",
    OA: "",
    questionFrame: "",
    nextQuestion: "",
    linkState: {},
    disablePrevButton: false,
    disableNextButton: false,
    loading: true,
    timerSeconds: 0,
    percentile: "",
    stats: "",
    numAnswers: "",
    isRC: false,
    isRCbtn: "none",
    rcQuestionNumber: 1,
    disableRCNext: false,
    disableRCPrev: true,
    totalRCQuestions: 1,
  };

  async componentDidMount() {
    // console.log(this.props.location.state);
    // set questionsList, linkState and page
    await this.setState({ ...this.props.location.state });
    if (
      this.state.linkState.url ===
      "https://gmatclub.com/forum/reading-comprehension-rc-137/index-"
    ) {
      this.setState({ isRC: true, isRCbtn: "" });
    }
    // console.log(this.state.questionsList);
    // get new questions. pass link of question
    let link = this.state.questionsList[this.state.questionNumber].link;
    let currentQuestion = await this.getQuestionPage(link);
    this.setState({ currentQuestion, loading: false });
    this.startTimer();
    // firstTimeTimer = 0;
    // preload next question
    // check if last question.
    if (this.state.questionNumber !== this.state.questionsList.length - 1) {
      // if this is not the last question, get next question
      let nextLink = this.state.questionsList[this.state.questionNumber + 1]
        .link;
      let nextQuestion = await this.getQuestionPage(nextLink);
      // console.log(nextLink);
      this.setState({ nextQuestion });
    } else {
      await this.nextQuestionsList();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.questionNumber === 0 && !this.state.disablePrevButton) {
      this.setState({ disablePrevButton: true });
    } else if (
      this.state.questionNumber !== 0 &&
      this.state.disablePrevButton
    ) {
      this.setState({ disablePrevButton: false });
    }

    // if (
    //   prevState.questionNumber !== this.state.questionNumber &&
    //   !firstTimeTimer
    // ) {
    if (prevState.questionNumber !== this.state.questionNumber) {
      this.resetTimer();
      // this.stopTimer();
      this.resetRC();
      this.startTimer();
      this.setState({ OA: "" });
    }

    if (prevState.rcQuestionNumber !== this.state.rcQuestionNumber) {
      this.rcQuestionChange();
    }

    let { currentQuestion, prevQuestion, nextQuestion } = this.state;

    if (prevState.questionNumber < this.state.questionNumber) {
      // nextButton clicked
      if (nextQuestion && nextQuestion !== currentQuestion) {
        await this.setState({
          prevQuestion: currentQuestion,
          currentQuestion: nextQuestion,
          nextQuestion: "",
          loading: false,
        });
      } else {
        await this.setState({ loading: true, prevQuestion: currentQuestion });
        let link = this.state.questionsList[this.state.questionNumber].link;
        currentQuestion = await this.getQuestionPage(link);
        await this.setState({
          currentQuestion,
          loading: false,
          nextQuestion: "",
        });
      }
    } else if (prevState.questionNumber > this.state.questionNumber) {
      // prevButton clicked
      if (prevQuestion && prevQuestion !== currentQuestion) {
        this.setState({
          nextQuestion: currentQuestion,
          currentQuestion: prevQuestion,
          prevQuestion: "",
          loading: false,
        });
      } else {
        await this.setState({ nextQuestion: currentQuestion, loading: true });
        let link = this.state.questionsList[this.state.questionNumber].link;
        currentQuestion = await this.getQuestionPage(link);
        await this.setState({
          currentQuestion,
          loading: false,
          prevQuestion: "",
        });
      }
    }

    if (prevState.questionNumber !== this.state.questionNumber) {
      this.preLoadQuestions();
    }
  }
  componentWillUnmount() {
    this.stopTimer();
  }

  getQuestionPage = async (link) => {
    let u = await localStorage.getItem("phpbb3_2oaiz_u");
    let sid = await localStorage.getItem("phpbb3_2oaiz_sid");
    // let d = await localStorage.getItem("phpbb3_2oaiz_d");
    let k = await localStorage.getItem("phpbb3_2oaiz_k");
    let cookie = `phpbb3_2oaiz_u=${u};phpbb3_2oaiz_sid=${sid};phpbb3_2oaiz_k=${k};`;
    let currentQuestion = await axios
      // .get(`http://localhost:8080`, {
      .get(`https://gcweb-server.rover.thriftyengineer.com/`, {
        // .get(`/.netlify/functions/node-fetch`, {
        headers: {
          TargetURL: link,
          accept: "Accept: application/json",
          gccookie: cookie,
          // "phpbb3_2oaiz_u=982593; phpbb3_2oaiz_track=tf%3A%281%3A%285u0u%3A1%3B6c0b%3A1%3B4arw%3A1%3B%29%3B141%3A%2845p8%3A1%3B%29%3B%29%3Bt%3A%285u0u%3A86dhpe%3B6c0b%3A9ax9p8%3B4arw%3A6mg0yu%3B4dic%3A99yzn4%3B25mx%3A8uedr9%3B3m34%3A5wcctl%3B45p8%3A9lwv37%3B%29%3B; phpbb3_2oaiz_sid=6fd219f3dbc9731a6f32f305291849a3; phpbb3_2oaiz_k=974e3ec1c83f5d9c;phpbb3_2oaiz_d=ZgxKEBhNObsunQF9jzmHYe32a4TL1k6ySUVrXlAJpWoI5f07vD8PCqwRdGtMci;",
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          const $ = cheerio.load(response.data);
          this.setState({ questionFrame: $.html() });
          let answer = $(".downRow").text().trim().substr(0, 1);
          // let answer2 = $(".spoiler-hidden").text().trim().substr(0, 1);
          // console.log(answer2);
          $(".item.twoRowsBlock").remove();
          $(".post_signature").remove();
          $(".spoiler").remove();
          $(
            'div[style*="border: 1px solid black; padding: 10px; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"]'
          ).remove();
          $(".item.twoRowsBlock div").remove();
          $(".bbcodeBoxOut").toggleClass("row");
          $(".bbcodeBoxIn").toggleClass("col overflow-auto");
          console.log($("#timer_message").html());
          // $("img")
          //   .toArray()
          //   .forEach((imagex) => {
          //     if (imagex.attribs.src.indexOf("http") == -1) {
          //       $('img[src^='+imagex.attribs.src+']').replaceWith(function () {
          //         const src = $(this).attr("src");
          //         if (src.indexOf("s3.amazon.com")) {
          //           src = src.replace("s3.amazon.com", "storage.azure");
          //         }
          //         return $(this).attr(
          //           "src",
          //           src.replace("http://", "https://")
          //         );
          //       });
          //       // console.log($().find(imagex.attribs.src));
          //       // console.log(imagex.attribs.src);
          //     }
          //     // if it doesnt exit, seracr for src in $ and replace src
          //   });
          // console.log($("img"));
          let totalRCQuestions = $(".placeholderTimerRC").length;
          let question = $(".item.text").html();
          // console.log($(".item.text").html());
          // console.log(answer.substr(0, 1));
          await window.DrawMathJax();
          if (answer) {
            return [question, answer, totalRCQuestions];
          }
          return [question, null, totalRCQuestions];
        }
        //   console.log(res.data);
      });
    return currentQuestion;
  };

  preLoadQuestions = async () => {
    let { currentQuestion, prevQuestion, nextQuestion } = this.state;
    //preload prev question
    if (
      this.state.questionNumber > 0 &&
      (!this.state.prevQuestion || prevQuestion === currentQuestion)
    ) {
      let link = this.state.questionsList[this.state.questionNumber - 1].link;
      prevQuestion = await this.getQuestionPage(link);
      this.setState({ prevQuestion });
    }
    //preload next question
    if (this.state.questionNumber > this.state.questionsList.length - 2) {
      await this.nextQuestionsList();
      // this.preLoadQuestions()
    }
    // if(this.state.questionNumber !== this.state.questionsList.length-1 && (!this.state.nextQuestion || nextQuestion===currentQuestion)){
    if (!this.state.nextQuestion || nextQuestion === currentQuestion) {
      let link = this.state.questionsList[this.state.questionNumber + 1].link;
      nextQuestion = await this.getQuestionPage(link);
      this.setState({ nextQuestion });
    }
  };

  nextQuestionsList = async () => {
    let { questionsList } = this.state;
    let newQuestions = await getQuestions(
      this.state.linkState,
      this.state.page + 1
    );
    questionsList = questionsList.concat(newQuestions);
    console.log(questionsList);
    await this.setState({ questionsList });
  };

  submitAnswer = async (option) => {
    console.log(option);
    let topicId = this.state.questionsList[this.state.questionNumber].link;
    topicId = topicId
      .substring(topicId.indexOf(".html") - 8, topicId.indexOf(".html"))
      .replace(/\D/g, "");
    // console.log(topicId);
    let r = Math.random();
    let postLink = `https://gmatclub.com/forum/timer.php?topic_id=${topicId}&user_id=1001966&timer_duration=${this.state.timerSeconds}&timer_answer=1${option}&r=${r}`;
    if (this.state.isRC) {
      postLink = `https://gmatclub.com/forum/timer_rc.php?topic_id=${topicId}&question_id=${this.state.rcQuestionNumber}&user_id=1001966&timer_duration=${this.state.timerSeconds}&timer_answer=1${option}&r=${r}`;
    }
    console.log(postLink);
    let u = await localStorage.getItem("phpbb3_2oaiz_u");
    let sid = await localStorage.getItem("phpbb3_2oaiz_sid");
    // let d = await localStorage.getItem("phpbb3_2oaiz_d");
    let k = await localStorage.getItem("phpbb3_2oaiz_k");
    let cookie = `phpbb3_2oaiz_u=${u};phpbb3_2oaiz_sid=${sid};phpbb3_2oaiz_k=${k};`;
    axios
      .get(`https://gcweb-server.rover.thriftyengineer.com/`, {
        // .get(`/.netlify/functions/node-fetch`, {
        headers: {
          TargetURL: postLink,
          accept: "Accept: application/json",
          gccookie: cookie,
          // "phpbb3_2oaiz_u=982593; phpbb3_2oaiz_track=tf%3A%281%3A%285u0u%3A1%3B6c0b%3A1%3B4arw%3A1%3B%29%3B141%3A%2845p8%3A1%3B%29%3B%29%3Bt%3A%285u0u%3A86dhpe%3B6c0b%3A9ax9p8%3B4arw%3A6mg0yu%3B4dic%3A99yzn4%3B25mx%3A8uedr9%3B3m34%3A5wcctl%3B45p8%3A9lwv37%3B%29%3B; phpbb3_2oaiz_sid=6fd219f3dbc9731a6f32f305291849a3; phpbb3_2oaiz_k=974e3ec1c83f5d9c;phpbb3_2oaiz_d=ZgxKEBhNObsunQF9jzmHYe32a4TL1k6ySUVrXlAJpWoI5f07vD8PCqwRdGtMci;",
        },
      })
      .then((res) => {
        this.stopTimer();
        if (res.status === 200) {
          let parser = new DOMParser();
          // console.log(
          //   res.data.substring(res.data.indexOf("<?xml"), res.data.length)
          // );
          let xmlDoc = parser.parseFromString(
            res.data.substring(res.data.indexOf("<?xml"), res.data.length),
            "text/xml"
          );
          // console.log(xmlDoc);
          let officialAnswer = xmlDoc.getElementsByTagName("offical_answers")[0]
            .textContent;
          // console.log(officialAnswer);
          if (officialAnswer !== 0) {
            document
              .getElementById(`${officialAnswer}`)
              .classList.add("btn-success");
            // this.state.questionsList[this.state.questionNumber].attempted = "Last answer was correct"
            // eslint-disable-next-line
            if (officialAnswer != option) {
              document.getElementById(`${option}`).classList.add("btn-danger");
            }
            this.setState({ OA: "" });
          } else {
            // OA Not available
            this.setState({ OA: "OA Not Available" });
          }
          // document.getElementById(`${option}`).classList.add("btn-danger");
          let a = xmlDoc.getElementsByTagName("percentage_answers_a")[0]
            .textContent;
          let b = xmlDoc.getElementsByTagName("percentage_answers_b")[0]
            .textContent;
          let c = xmlDoc.getElementsByTagName("percentage_answers_c")[0]
            .textContent;
          let d = xmlDoc.getElementsByTagName("percentage_answers_d")[0]
            .textContent;
          let e = xmlDoc.getElementsByTagName("percentage_answers_e")[0]
            .textContent;
          let stats = `A:${a}% B:${b}% C:${c}% D:${d}% E:${e}%`;

          let percentile = `Difficulty: ${
            xmlDoc.getElementsByTagName("percentile")[0].textContent
          }`;
          let numAnswers = `Num Answers: ${
            xmlDoc.getElementsByTagName("amount_of_answers")[0].textContent
          }`;

          this.setState({ stats, percentile, numAnswers });

          // console.log(officialAnswer);
          // this.stopTimer();
        } else {
          console.log(res);
        }
      });
  };
  rcQuestionChange = () => {
    if (this.state.rcQuestionNumber > 1) {
      this.setState({ disableRCPrev: false });
    }
    if (this.state.rcQuestionNumber === 1) {
      this.setState({ disableRCPrev: true });
    }
    if (this.state.rcQuestionNumber === this.state.currentQuestion[2]) {
      this.setState({ disableRCNext: true });
    } else {
      this.setState({ disableRCNext: false });
    }
    this.resetTimer();
    this.startTimer();
    document.getElementById("1").classList.remove("btn-success", "btn-danger");
    document.getElementById("3").classList.remove("btn-success", "btn-danger");
    document.getElementById("2").classList.remove("btn-success", "btn-danger");
    document.getElementById("4").classList.remove("btn-success", "btn-danger");
    document.getElementById("5").classList.remove("btn-success", "btn-danger");
  };
  resetRC = () => {
    this.setState({
      rcQuestionNumber: 1,
      disableRCNext: false,
      disableRCPrev: true,
    });
  };
  resetTimer = () => {
    this.setState({
      timerSeconds: 0,
      percentile: "",
      numAnswers: "",
      stats: "",
    });
  };
  startTimer = () => {
    if (!timer) {
      timer = setInterval(() => {
        this.setState({ timerSeconds: this.state.timerSeconds + 1 });
      }, 1000);
    }
  };
  stopTimer = () => {
    clearInterval(timer);
    timer = null;
  };

  // testProxy = async () => {
  //   let u = await localStorage.getItem("phpbb3_2oaiz_u");
  //   let sid = await localStorage.getItem("phpbb3_2oaiz_sid");
  //   let d = await localStorage.getItem("phpbb3_2oaiz_d");
  //   let k = await localStorage.getItem("phpbb3_2oaiz_k");
  //   let cookie = `phpbb3_2oaiz_u=${u};phpbb3_2oaiz_sid=${sid};phpbb3_2oaiz_k=${k};`;
  //   axios
  //     .post(`http://localhost:8080/`, {
  //       headers: {
  //         TargetURL: "https://httpbin.org/post",
  //         accept: "Accept: application/json",
  //         gccookie: cookie,
  //         cookie,
  //         // "phpbb3_2oaiz_u=982593; phpbb3_2oaiz_track=tf%3A%281%3A%285u0u%3A1%3B6c0b%3A1%3B4arw%3A1%3B%29%3B141%3A%2845p8%3A1%3B%29%3B%29%3Bt%3A%285u0u%3A86dhpe%3B6c0b%3A9ax9p8%3B4arw%3A6mg0yu%3B4dic%3A99yzn4%3B25mx%3A8uedr9%3B3m34%3A5wcctl%3B45p8%3A9lwv37%3B%29%3B; phpbb3_2oaiz_sid=6fd219f3dbc9731a6f32f305291849a3; phpbb3_2oaiz_k=974e3ec1c83f5d9c;phpbb3_2oaiz_d=ZgxKEBhNObsunQF9jzmHYe32a4TL1k6ySUVrXlAJpWoI5f07vD8PCqwRdGtMci;",
  //       },
  //     })
  //     .then((res) => {
  //       this.stopTimer();
  //       if (res.status === 200) {
  //         console.log(res);
  //       } else {
  //         console.log(res);
  //       }
  //     });
  // };

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    let completionState = "";
    // this.state.questionsList[this.state.questionNumber];
    // if (this.state.attempt !== prevState.attempt) {
    if (
      this.state.questionsList[this.state.questionNumber].attempted ===
      "Last answer was correct"
    ) {
      // completionState=green tick
      completionState = <CheckCircleFill color="green" className="" />;
      // this.setState({
      //   completionState: <CheckCircleFill color="green" className="" />,
      // });
    } else if (
      this.state.questionsList[this.state.questionNumber].attempted ===
      undefined
    ) {
      // completionState=blank
      completionState = <XCircleFill color="red" />;
      // this.setState({ completionState: <XCircleFill color="red" /> });
    } else {
      // completionState=red cross
      completionState = "";
      // this.setState({ completionState: "" });
    }
    // }
    return (
      //   <div className=""> {this.state.currentQuestion}</div>
      <div className="container pt-3">
        <div className="row">
          <button
            className="btn btn-large btn-primary"
            disabled={this.state.disablePrevButton}
            onClick={() =>
              this.setState({
                questionNumber: this.state.questionNumber - 1,
              })
            }
          >
            Previous Question
          </button>
          <div className="timer mx-auto">
            <label htmlFor="tag" className="mr-2">
              Timer{" "}
            </label>
            <label htmlFor="minutes">
              {parseInt(this.state.timerSeconds / 60)}
            </label>
            :<label htmlFor="seconds">{this.state.timerSeconds % 60}</label>
            <span
              className="ml-1"
              onClick={() => {
                this.setState({ timerSeconds: 0 });
              }}
              style={{ cursor: "pointer" }}
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-counterclockwise"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.83 6.706a5 5 0 0 0-7.103-3.16.5.5 0 1 1-.454-.892A6 6 0 1 1 2.545 5.5a.5.5 0 1 1 .91.417 5 5 0 1 0 9.375.789z"
                />
                <path
                  fillRule="evenodd"
                  d="M7.854.146a.5.5 0 0 0-.708 0l-2.5 2.5a.5.5 0 0 0 0 .708l2.5 2.5a.5.5 0 1 0 .708-.708L5.707 3 7.854.854a.5.5 0 0 0 0-.708z"
                />
              </svg>
            </span>
            {/* Completion state */}
            {/* <span className="ml-1">{this.state.completionState}</span> */}
            <span className="ml-1">{completionState}</span>
            <span className="ml-1">{this.state.OA}</span>
          </div>

          <button
            className="btn btn-large btn-primary"
            disabled={this.state.disableNextButton}
            onClick={() =>
              this.setState({
                questionNumber: this.state.questionNumber + 1,
              })
            }
          >
            Next Question
          </button>
        </div>
        <div className="" key={this.state.questionNumber}>
          <div className="row mt-3 d-flex justify-content-around">
            <div className="">
              {this.state.percentile} {this.state.numAnswers}
            </div>
            {/* <div className=""></div> */}
            <button
              id="rcPrev"
              className="btn btn-outline-dark"
              disabled={this.state.disableRCPrev}
              onClick={() =>
                this.setState({
                  rcQuestionNumber: this.state.rcQuestionNumber - 1,
                })
              }
              style={{ display: this.state.isRCbtn }}
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-left"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
              Prev
            </button>
            <div className="" id="mainOptions">
              <span className="mr-2">Q {this.state.rcQuestionNumber}</span>
              <button
                className="btn btn-outline-info mr-3"
                onClick={() => this.submitAnswer(1)}
                id={`1`}
              >
                A
              </button>
              <button
                className="btn btn-outline-info mr-3"
                onClick={() => this.submitAnswer(2)}
                id={`2`}
              >
                B
              </button>
              <button
                className="btn btn-outline-info mr-3"
                onClick={() => this.submitAnswer(3)}
                id={`3`}
              >
                C
              </button>
              <button
                className="btn btn-outline-info mr-3"
                onClick={() => this.submitAnswer(4)}
                id={`4`}
              >
                D
              </button>
              <button
                className="btn btn-outline-info mr-3"
                onClick={() => this.submitAnswer(5)}
                id={`5`}
              >
                E
              </button>
            </div>
            <button
              id="rcNext"
              className="btn btn-outline-dark"
              disabled={this.state.disableRCNext}
              onClick={() =>
                this.setState({
                  rcQuestionNumber: this.state.rcQuestionNumber + 1,
                })
              }
              style={{ display: this.state.isRCbtn }}
            >
              Next
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-arrow-right-short"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"
                />
                <path
                  fillRule="evenodd"
                  d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"
                />
              </svg>
            </button>

            <div className="">{this.state.stats}</div>
          </div>
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: this.state.currentQuestion[0] }}
          />
          <a
            href={this.state.questionsList[this.state.questionNumber].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            View question in a new window on Forum
          </a>
          <br />
          <br />

          {/* <button
            className="btn btn-link"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseIframe"
            aria-expanded="false"
            aria-controls="collapseIframe"
          >
            View in page
          </button>
          <div className="collapse" id="collapseIframe">
            test
            <iframe
              src={this.state.questionFrame}
              width="100%"
              frameBorder="0"
            ></iframe>
          </div> */}
          {/* <button onClick={this.testProxy}>Test Proxy</button> */}
        </div>
      </div>
    );
  }
}

export default Question;
