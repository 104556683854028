import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Footer from "../components/footer";

function Dashboard(props) {
  let history = useHistory();
  const handleClick = (url) => {
    history.push({
      pathname: url,
    });
  };
  let location = useLocation();
  // console.log(location.pathname);
  let element;
  // if (location.pathname === "/quizz") {
  //   element = (
  //     <div
  //       className="container d-flex justify-content-center align-content-center"
  //       style={{ height: "100vh" }}
  //     >
  //       <button
  //         className="btn btn-large btn-primary mx-3 my-auto"
  //         style={{ height: "25%", width: "25%" }}
  //         onClick={() => handleClick("/quizz/cr")}
  //       >
  //         CR
  //       </button>
  //       <button
  //         className="btn btn-large btn-primary mx-3 my-auto"
  //         style={{ height: "25%", width: "25%" }}
  //         onClick={() => handleClick("/quizz/sc")}
  //       >
  //         SC
  //       </button>
  //       <button
  //         className="btn btn-large btn-primary mx-3 my-auto"
  //         style={{ height: "25%", width: "25%" }}
  //         onClick={() => handleClick("/quizz/rc")}
  //       >
  //         RC
  //       </button>
  //       <button
  //         className="btn btn-large btn-primary mx-3 my-auto"
  //         style={{ height: "25%", width: "25%" }}
  //         onClick={() => handleClick("/quizz/all")}
  //       >
  //         All
  //       </button>
  //     </div>
  //   );
  // } else if (location.pathname === "/verbal") {
  if (location.pathname === "/verbal") {
    element = (
      <div
        className="container d-flex justify-content-center align-content-center"
        style={{ height: "100vh" }}
      >
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/verbal/cr")}
        >
          CR
        </button>
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/verbal/sc")}
        >
          SC
        </button>
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/verbal/rc")}
        >
          RC
        </button>
        <Footer />
      </div>
    );
  } else if (location.pathname === "/quant") {
    element = (
      <div
        className="container d-flex justify-content-center align-content-center"
        style={{ height: "100vh" }}
      >
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/quant/ds")}
        >
          Data Sufficiency
        </button>
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/quant/ps")}
        >
          Problem Solving
        </button>
        <Footer />
      </div>
    );
  } else {
    element = (
      <div
        className="container d-flex justify-content-center align-content-center"
        style={{ height: "100vh" }}
      >
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/quant")}
        >
          Quant
        </button>
        <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/verbal")}
        >
          Verbal
        </button>
        {/* <button
          className="btn btn-large btn-primary mx-3 my-auto"
          style={{ height: "25%", width: "25%" }}
          onClick={() => handleClick("/quizz")}
        >
          Quizz
        </button> */}
        <Footer />
      </div>
    );
  }
  // element = element + <Footer />;
  return element;
}

export default Dashboard;
