import React, { useState } from "react";
// import axios from "axios";
import { Gear } from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, Button } from "react-bootstrap";

export default function App() {
  let [isOpen, setIsOpen] = useState(false);
  let openModal = () => setIsOpen(true);
  let closeModal = () => setIsOpen(false);
  // let [username, setUsername] = useState("");
  // let [password, setPassword] = useState("");
  let [phpbb3_2oaiz_k, setphpbb3_2oaiz_k] = useState(
    localStorage.getItem("phpbb3_2oaiz_k") || ""
  );
  let [phpbb3_2oaiz_sid, setphpbb3_2oaiz_sid] = useState(
    localStorage.getItem("phpbb3_2oaiz_sid") || ""
  );
  // let [phpbb3_2oaiz_d, setphpbb3_2oaiz_d] = useState(
  //   localStorage.getItem("phpbb3_2oaiz_d") || ""
  // );
  let [phpbb3_2oaiz_u, setphpbb3_2oaiz_u] = useState(
    localStorage.getItem("phpbb3_2oaiz_u") || ""
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(username);
    // console.log(password);
    // axios
    //   // .post(`https://gcweb-server.rover.thriftyengineer.com/`, {
    //   .post(`http://localhost:8080/`, {
    //     headers: {
    //       TargetURL: "https://gmatclub.com/forum/ucp.php?mode=login",
    //       accept: "Accept: application/json",
    //       gccookie: "",
    //       username,
    //       password,
    //     },
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((e) => console.log(e));
    localStorage.setItem("phpbb3_2oaiz_u", phpbb3_2oaiz_u);
    localStorage.setItem("phpbb3_2oaiz_sid", phpbb3_2oaiz_sid);
    localStorage.setItem("phpbb3_2oaiz_k", phpbb3_2oaiz_k);
    // localStorage.setItem("phpbb3_2oaiz_d", phpbb3_2oaiz_d);
  };
  return (
    <>
      <button
        className="btn btn-primary float-left fixed-bottom ml-5 mb-5 d-flex justify-content-center align-items-center"
        style={{ borderRadius: "50%", width: "3em", height: "3em" }}
        onClick={openModal}
      >
        <Gear className="" />
      </button>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cookie values</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <iframe
            src="https://gmatclub.com/forum/ucp.php?mode=login"
            width="100%"
          ></iframe> */}
          <form onSubmit={handleSubmit}>
            <label htmlFor="phpbb3_2oaiz_u" className="mr-2">
              phpbb3_2oaiz_u
            </label>
            <input
              type="text"
              value={phpbb3_2oaiz_u}
              onChange={(e) => setphpbb3_2oaiz_u(e.target.value)}
            />
            <br />
            <label htmlFor="phpbb3_2oaiz_k" className="mr-2">
              phpbb3_2oaiz_k
            </label>
            <input
              type="text"
              value={phpbb3_2oaiz_k}
              onChange={(e) => setphpbb3_2oaiz_k(e.target.value)}
            />
            <br />
            <label htmlFor="phpbb3_2oaiz_sid" className="mr-2">
              phpbb3_2oaiz_sid
            </label>
            <input
              type="text"
              value={phpbb3_2oaiz_sid}
              onChange={(e) => setphpbb3_2oaiz_sid(e.target.value)}
            />
            <br />
            {/* <label htmlFor="phpbb3_2oaiz_d" className="mr-2">
              phpbb3_2oaiz_d
            </label>
            <input
              type="text"
              value={phpbb3_2oaiz_d}
              onChange={(e) => setphpbb3_2oaiz_d(e.target.value)}
            />
            <br /> */}
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
