import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

import SelectionsView from "./views/selectionsView";
// import QuizzView from "./views/quizzView";
import QuestionsList from "./views/questionsListView";
import Question from "./views/question";
import Dashboard from "./views/dashboard";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/quant/ds" component={SelectionsView} />
      <Route path="/quant/ps" component={SelectionsView} />
      <Route path="/verbal/cr" component={SelectionsView} />
      <Route path="/verbal/rc" component={SelectionsView} />
      <Route path="/verbal/sc" component={SelectionsView} />
      {/* <Route path="/quizz/sc" component={QuizzView} />
      <Route path="/quizz/rc" component={QuizzView} />
      <Route path="/quizz/cr" component={QuizzView} />
      <Route path="/quizz/all" component={QuizzView} /> */}
      <Route path="/questionslist" component={QuestionsList} />
      <Route path="/question" component={Question} />
      <Route path="/" component={Dashboard} />
    </Switch>
  </Router>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
