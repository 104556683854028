import React from "react";
import { Formik, Form, Field } from "formik";
import "bootstrap/dist/css/bootstrap.css";

const SelectionsView = (props) => {
  // console.log(props);
  let CRDifficultyLevels = {
    168: "700+",
    226: "600-700",
    227: "Sub-600",
  };
  let RCDifficultyLevels = {
    162: "700+",
    228: "600-700",
    229: "Sub-600",
  };
  let SCDifficultyLevels = {
    172: "700+",
    231: "600-700",
    232: "Sub-600",
  };

  let CRTags = {
    200: "Additional Evidence",
    115: "Assumption",
    116: "Conclusion",
    118: "Strengthen Argument",
    117: "Weaken Argument",
    119: "Bold Face CR",
    120: "Inference",
    121: "Must Be True",
    122: "Cause and Effect",
    123: "Resolve a Paradox",
    124: "Numbers and Percents",
    201: "Logical Flaw",
    202: "Similar Reasoning",
    207: "Evaluate Argument",
    203: "Complete the Passage",
    247: "Method of Reasoning",
    93: "CR Resources",
    171: "Debatable OA",
    549: "Except",
    1236: "Poor Quality",
  };
  let RCTags = {
    125: "Humanities",
    126: "Science",
    128: "Business",
    167: "Social Science",
    129: "Long Passage",
    130: "Short Passage",
    163: "Debatable OA",
    248: "Tone",
    94: "RC Resources",
    166: "RC Strategies",
  };
  let SCTags = {
    1189: "Absolute Phrase",
    137: "Adjectives & Adverbs",
    177: "Clauses",
    140: "Comparisons",
    139: "Conjunctions",
    234: "Construction",
    175: "Debatable OA",
    235: "Diction",
    208: "Gerund",
    236: "Grammatical Construction",
    142: "Idioms",
    209: "If-Then",
    210: "Infinitive",
    1190: "Meaning",
    138: "Modifiers",
    135: "Nouns",
    487: "Out of Scope/Too Hard",
    134: "Pronouns",
    141: "Parallel Structure",
    212: "Participle Parallelism",
    1220: "Poor Quality",
    144: "Punctuation",
    1310: "Redundancy",
    145: "Resources",
    143: "Style and Usage",
    131: "Subject Verb Agreement",
    214: "Subjunctive",
    213: "Use of Being",
    132: "Verb/Tense Form",
    133: "Verb Voice",
    176: "Which/That",
    237: "Such as/Like",
  };

  let PSDifficultyLevels = {
    187: "700+",
    216: "600-700",
    217: "Sub-600",
  };
  let DSDifficultyLevels = {
    180: "700+",
    222: "600-700",
    223: "Sub-600",
  };
  let DSTags = {
    37: "Absolute Values/Modulus",
    29: "Algebra",
    30: "Arithmetic",
    31: "Combinations",
    41: "Coordinate Geometry",
    44: "Distance/Rate Problems",
    354: "Divisibility/Multiples/Factors",
    39: "Exponents/Powers",
    36: "Fractions/Ratios/Decimals",
    40: "Functions and Custom Characters",
    32: "Geometry",
    240: "Graphs and Illustrations",
    184: "Inequalities",
    42: "Min/Max Problems",
    43: "Mixture Problems",
    38: "Number Properties",
    45: "Overlapping Sets",
    484: "Out of Scope - Too Hard",
    192: "Percent and Interest Problems",
    181: "Poor Quality",
    33: "Probability",
    198: "Remainders",
    49: "Roots",
    111: "Sequences",
    34: "Statistics and Sets Problems",
    183: "Word Problems",
    46: "Work/Rate Problems",
  };
  let PSTags = {
    58: "Absolute Values/Modulus",
    50: "Algebra",
    51: "Arithmetic",
    52: "Combinations",
    62: "Coordinate Geometry",
    64: "Distance/Rate Problems",
    185: "Divisibility/Multiples/Factors",
    60: "Exponents/Powers",
    57: "Fractions/Ratios/Decimals",
    61: "Functions and Custom Characters",
    53: "Geometry",
    239: "Graphs and Illustrations",
    189: "Inequalities",
    63: "Min/Max Problems",
    114: "Mixture Problems",
    193: "Must or Could be True Questions",
    59: "Number Properties",
    485: "Out of Scope - Too Hard",
    65: "Overlapping Sets",
    191: "Percent and Interest Problems",
    188: "Poor Quality",
    54: "Probability",
    199: "Remainders",
    113: "Roots",
    112: "Sequences",
    55: "Statistics and Sets Problems",
    56: "Word Problems",
    66: "Work/Rate Problems",
  };

  const generateForm = (list, listName) => {
    let returnList = [];
    Object.keys(list).forEach((key) => {
      returnList.push(
        <div key={key}>
          <label className="form-check-label">
            <Field
              type="checkbox"
              name={listName}
              value={key}
              className="form-check-input"
            />
            {list[key]}
          </label>
          <br />
        </div>
      );
    });
    return returnList;
  };

  let difficultyLevelsForm;
  let topics;

  if (props.location.pathname === "/verbal/rc") {
    difficultyLevelsForm = generateForm(RCDifficultyLevels, "difficulty");
    topics = generateForm(RCTags, "topics");
  } else if (props.location.pathname === "/verbal/cr") {
    difficultyLevelsForm = generateForm(CRDifficultyLevels, "difficulty");
    topics = generateForm(CRTags, "topics");
  } else if (props.location.pathname === "/verbal/sc") {
    difficultyLevelsForm = generateForm(SCDifficultyLevels, "difficulty");
    topics = generateForm(SCTags, "topics");
  } else if (props.location.pathname === "/quant/ps") {
    difficultyLevelsForm = generateForm(PSDifficultyLevels, "difficulty");
    topics = generateForm(PSTags, "topics");
  } else if (props.location.pathname === "/quant/ds") {
    difficultyLevelsForm = generateForm(DSDifficultyLevels, "difficulty");
    topics = generateForm(DSTags, "topics");
  }

  const handleSubmit = (values) => {
    let url = "";
    let page = 0;
    let tags = "";
    if (props.location.pathname === "/verbal/rc") {
      url = `https://gmatclub.com/forum/reading-comprehension-rc-137/index-`;
    } else if (props.location.pathname === "/verbal/sc") {
      url = `https://gmatclub.com/forum/sentence-correction-sc-138/index-`;
    } else if (props.location.pathname === "/verbal/cr") {
      url = `https://gmatclub.com/forum/critical-reasoning-cr-139/index-`;
    } else if (props.location.pathname === "/quant/ps") {
      url = `https://gmatclub.com/forum/problem-solving-ps-140/index-`;
    } else if (props.location.pathname === "/quant/ds") {
      url = `https://gmatclub.com/forum/data-sufficiency-ds-141/index-`;
    }
    values.difficulty.forEach((tag) => {
      // console.log(tag);
      tags += `&selected_tags%5B%5D=${tag}`;
    });
    values.topics.forEach((tag) => {
      tags += `&selected_tags%5B%5D=${tag}`;
    });

    props.history.push("/questionslist", { url, page, tags });
  };

  return (
    <Formik
      initialValues={{
        difficulty: [],
        topics: [],
      }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values }) => (
        <Form>
          <div className="form-check container mt-3 mb-5">
            <div className="row">
              <div className="col">
                <div id="Difficulty">Difficulty</div>
                <div role="group" aria-labelledby="Difficulty">
                  {difficultyLevelsForm}
                </div>
              </div>
              <div className="col">
                <div id="RC">Topic</div>
                <div role="group" aria-labelledby="RC">
                  {topics}
                </div>
              </div>
            </div>
            <button type="submit" className="btn btn-large btn-primary">
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SelectionsView;
