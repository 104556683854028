import React, { Component } from "react";
import { Link } from "react-router-dom";
import getQuestions from "../components/getQuestions";

class QuestionsList extends Component {
  state = { page: 0, questions: [], loading: true, disablePrevButton: true };

  async componentDidMount() {
    // console.log(this.props);
    // console.log(this.state);
    let questions = await getQuestions(
      this.props.location.state,
      this.state.page
    );
    this.generateQuestionCards(questions, this.props);
    // console.log(this.state.questions);
    // console.log(questions);
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      let questions = await getQuestions(
        this.props.location.state,
        this.state.page
      );
      this.generateQuestionCards(questions, this.props);
      // this.setState({ questions: questionCards });
      if (this.state.page === 0 && this.state.page !== prevState.page) {
        this.setState({ disablePrevButton: true });
      } else if (this.state.page !== 0 && this.state.page !== prevState.page) {
        this.setState({ disablePrevButton: false });
      }
    }
  }

  generateQuestionCards = (questionsList, props) => {
    let currentPage = this.state.page;
    let linkState = props.location.state;
    // console.log(linkState);
    let questionCards = [];
    let questionNumber = 0;
    // let attempt = []
    questionsList.forEach((question) => {
      console.log(question.attempted);
      // let attempt = question.attempted;
      let attemptedTextColor;
      if (question.attempted === "Last answer was correct") {
        attemptedTextColor = "mb-1 text-success";
      } else if (question.attempted === undefined) {
        attemptedTextColor = "mb-1 text-danger";
      } else {
        attemptedTextColor = "mb-1";
      }

      // console.log(attemptedTextColor);
      // console.log(question);
      let tagsRender = [];
      question.tags.forEach((tag) => {
        for (const property in tag) {
          tagsRender.push(
            <a key={property} href={property}>
              {tag[property]}{" "}
            </a>
          );
          // console.log(`tag[${property}] = ${tag[property]}`);
        }
      });
      questionCards.push(
        <div
          className="list-group-item list-group-item-action"
          key={question.link}
        >
          <div className="d-flex w-100 justify-content-between">
            <Link
              className=""
              to={{
                pathname: "/question",
                state: {
                  questionsList,
                  questionNumber,
                  currentPage,
                  linkState,
                  // attemptList,
                },
              }}
            >
              <h5 className={attemptedTextColor}>{question.title}</h5>
            </Link>
            <small>Views: {question.views}</small>
          </div>
          <p className="mb-1">
            {/* Donec id elit non mi porta gravida at eget metus. Maecenas sed
                    diam eget risus varius blandit. */}
          </p>
          <small>Tags: {tagsRender} </small>
        </div>
      );
      questionNumber++;
    });
    this.setState({ questions: questionCards, loading: false });
    // return questionCards;
  };
  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="container pt-3">
        {this.state.questions}
        <button
          className="btn btn-large btn-primary mt-3 mb-5"
          id="prevButton"
          onClick={() => {
            // console.log(this.state.page);
            if (this.state.page !== 0) {
              this.setState({ page: this.state.page - 1, loading: true });
            }
          }}
          disabled={this.state.disablePrevButton}
        >
          Previous Page
        </button>
        <button
          className="btn btn-large btn-primary mt-3 mb-5 float-right"
          onClick={() => {
            // console.log(this.state.page);
            this.setState({ page: this.state.page + 1, loading: true });
          }}
        >
          Next Page
        </button>
      </div>
    );
  }
}

export default QuestionsList;
