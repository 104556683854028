// import React from "react";
import axios from "axios";
import cheerio from "cheerio";

const scrapeQuestionsPage = async (response) => {
  if (response.status === 200) {
    const $ = cheerio.load(response.data);

    let rows = $(".table-topic-row")
      .map(function (i, el) {
        // this === el

        let lastPostTime = $(this)
          .find(".topic-table-column.table-topic-row9 .last-post-time")
          .map(function (i, el) {
            // this === el
            return new Date($(this).text());
          })
          .get();

        //   const scrapeThisPage = async (numberOfPostsToCheck) => {
        let mark = $(this)
          .find(".topic-table-column.topic-table-column1 a.icon span")
          .map(function (i, el) {
            // this === el
            // console.log(i + "." + $(this).attr("title"));
            return (
              // .find("img")
              $(this).attr("title")
            );
            // .replace(/\t/g, "")
            // .replace(/\n/g, "");
          })
          .get();

        let kudos = $(this)
          .find(".topic-table-column.topic-table-column2")
          .map(function (i, el) {
            // this === el
            // let temp
            // console.log(i + "." + $(this).children(".kudos-count").html());
            if ($(this).children(".kudos-count")) {
              return parseInt(
                $(this)
                  .children(".kudos-count")
                  .text()
                  .replace(/\t/g, "")
                  .replace(/\n/g, "")
              );
            }
            return 0;
          })
          .get();

        let er = $(this)
          .find(".topic-table-column.topic-table-column3")
          .map(function (i, el) {
            // this === el
            if ($(this).find(".icon-svg-expert-reply").length) {
              return true;
            } else {
              return false;
            }
          })
          .get();

        let title = $(this)
          .find(".topicTitle")
          // .text()
          // .replace(/\t/g, "")
          // .replace(/\n/g, "")
          // .trim();
          .map(function (i, el) {
            // i > 40 && console.log(i);
            // this === el
            return $(this).text().replace(/\t/g, "").replace(/\n/g, "").trim();
          })
          .get();
        // console.log(title);

        let link = $(this)
          .find(".topicsName a.topic-link")
          .map(function (i, el) {
            // this === el
            // let tempLink = $(this).attr("href");
            let finalLink = $(this).attr("href");
            // console.log(tempLink);
            // tempLink.indexOf("?sid") &&
            //   (finalLink = tempLink.substr(0, tempLink.indexOf("?sid")));
            return finalLink;
          })
          .get();

        let tags = [];
        $(this)
          .find(".topic-tags")
          .map(function (i, el) {
            // this === el
            let temp = $(el)
              .children("a")
              .map(function (i2, el2) {
                // this === el2
                let href = $(this).attr("href");
                let id = href
                  .slice(
                    href.indexOf("tag_id=") + 7,
                    href.indexOf("tag_id=") + 12
                  )
                  .replace(/\D/g, "");
                let tag = $(this).text();
                return { [id]: tag };
              })
              .get();
            tags.push(temp);
            return null;
          })
          .get();

        let attempted = $(this)
          .find(".timers-wrap")
          .map(function (i, el) {
            if ($(this).find(".timerMark").length) {
              return $(this)
                .find(".timerMarkImgTrue")
                .attr("data-castom-title");
            } else {
              return "false";
            }
          })
          .get();

        // console.log(
        //   $(this).html()
        //   // .toString()
        // );

        let author = $(this)
          .find(".topic-table-column.table-topic-row6 a")
          .map(function (i, el) {
            // this === el
            let memberLink = $(this).attr("href");
            let memberId = memberLink.slice(
              memberLink.indexOf("-") + 1,
              memberLink.indexOf(".")
            );
            let temp = [];
            temp.push([$(this).attr("title"), memberId]);
            return temp;
          })
          .get();

        let replies = $(this)
          .find(".topic-table-column.table-topic-row7")
          .map(function (i, el) {
            // this === el
            return parseInt(
              $(this).text().replace(/\t/g, "").replace(/\n/g, "")
            );
          })
          .get();

        let views = $(this)
          .find(".topic-table-column.table-topic-row8")
          .map(function (i, el) {
            // this === el
            return parseInt(
              $(this).text().replace(/\t/g, "").replace(/\n/g, "")
            );
          })
          .get();

        let latestPost = $(this)
          .find(
            ".topic-table-column.table-topic-row9 .last-post-author .latestPostIcon"
          )
          .map(function (i, el) {
            // this === el
            return $(this).attr("href");
          })
          .get();

        let latestPostAuthor = $(this)
          .find(
            ".topic-table-column.table-topic-row9 .last-post-author .latestPostIcon+a"
          )
          .map(function (i, el) {
            // this === el
            let memberLink = $(this).attr("href");
            let memberId = memberLink.slice(
              memberLink.indexOf("-") + 1,
              memberLink.indexOf(".")
            );
            let temp = [];
            temp.push([$(this).attr("title"), memberId]);
            return temp;
          })
          .get();

        let postsList = [];

        if (mark[0] === undefined || mark[0].includes("Announce")) {
          // console.log(mark[i]);
        } else {
          let packagedPost = {
            mark: mark[0],
            kudos: kudos[0],
            er: er[0],
            title: title[0],
            link: link[0],
            tags: tags[0],
            attempted: attempted[0],
            author: author[0],
            replies: replies[0],
            views: views[0],
            latestPost: latestPost[0],
            latestPostAuthor: latestPostAuthor[0],
            lastPostTime: lastPostTime[0],
          };

          postsList.push(packagedPost);
        }
        return postsList;
      })
      .get();

    return rows;
  } else {
    console.log(response);
  }
};

const getQuestions = async (state, page) => {
  //   getQuestions = async (props) => {
  // let currentPage = state.page;
  let link = `${state.url}${page * 50}.html?${state.tags}`;
  let u = await localStorage.getItem("phpbb3_2oaiz_u");
  let sid = await localStorage.getItem("phpbb3_2oaiz_sid");
  // let d = await localStorage.getItem("phpbb3_2oaiz_d");
  let k = await localStorage.getItem("phpbb3_2oaiz_k");
  // let cookie = `phpbb3_2oaiz_u=${u};phpbb3_2oaiz_sid=${sid};phpbb3_2oaiz_d=${d};phpbb3_2oaiz_k=${k};`;
  let cookie = `phpbb3_2oaiz_u=${u};phpbb3_2oaiz_sid=${sid};phpbb3_2oaiz_k=${k};`;
  // console.log(cookie);
  return (
    axios
      // .get(`/.netlify/functions/node-fetch`, {
      .get(`https://gcweb-server.rover.thriftyengineer.com/`, {
        headers: {
          TargetURL: link,
          accept: "Accept: application/json",
          gccookie: cookie,
          // "phpbb3_2oaiz_u=982593; phpbb3_2oaiz_track=tf%3A%281%3A%285u0u%3A1%3B6c0b%3A1%3B4arw%3A1%3B%29%3B141%3A%2845p8%3A1%3B%29%3B%29%3Bt%3A%285u0u%3A86dhpe%3B6c0b%3A9ax9p8%3B4arw%3A6mg0yu%3B4dic%3A99yzn4%3B25mx%3A8uedr9%3B3m34%3A5wcctl%3B45p8%3A9lwv37%3B%29%3B; phpbb3_2oaiz_sid=6fd219f3dbc9731a6f32f305291849a3; phpbb3_2oaiz_k=974e3ec1c83f5d9c;phpbb3_2oaiz_d=ZgxKEBhNObsunQF9jzmHYe32a4TL1k6ySUVrXlAJpWoI5f07vD8PCqwRdGtMci;",
        },
      })
      .then(async (res) => {
        let questionsList = await scrapeQuestionsPage(res);

        return questionsList;
      })
  );
  //   };
};

export default getQuestions;
